import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TableHeader from "../../containers/TableHeader";
import TableBody from "../../containers/TableBody";
import { useFetchInterceptor } from "../../hooks/useFetchInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { setChefs, setIsFetched, setLoading } from "../../redux/chefSlice";
import { Navigate } from "react-router-dom";
import { Comparator } from "../../utils/sorting";
import { ChefListsHeader } from "../../mocks/constants";
import "../../styles/table.scss";
import { Alert } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import CommonModal from "../../containers/Modal";
import Heading from "../../containers/Heading";
import ChefForm from "../../containers/ChefForm";

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => Comparator(a, b, orderBy)
    : (a, b) => -Comparator(a, b, orderBy);
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ChefLists() {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  const { chefs, loading, isFetched } = useSelector((state) => state.chef);
  const dispatch = useDispatch();
  const fetchApiInterceptors = useFetchInterceptor();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = useState("status");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: "" });
  const [error, setError] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [chefFormData, setChefFormData] = useState({});

  const getChefs = async () => {
    try {
      dispatch(setLoading(true));
      let options = {
        method: "GET",
        credentials: "include",
      };
      const result = await fetchApiInterceptors(
        `/api/admin/cheflists?page=${page}`,
        token,
        options
      );
      dispatch(setLoading(false));
      if (result.status) {
        dispatch(setChefs(result.data));
        setCount(result.count);
        dispatch(setIsFetched(true));
        // setNoOfPages(Math.ceil(result.count / 10));
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (token && !isFetched) getChefs();
  }, [token, dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () => {
      if (chefs.length) {
        return [...chefs].sort(getComparator(order, orderBy));
      }
    },
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, chefs]
  );

  const handleEdit = (e, id) => {
    const getChefData = [...chefs].filter(chef => chef._id === id);
    setChefFormData(getChefData);
    setEditForm(true);
  };

  const closeModal = () => {
    setEditForm(false);
  }

  const handleDelete = async (e, id) => {
    try {
      let options = {
        method: "PATCH", // not deleting it just updating the status
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      };
      dispatch(setLoading(true));
      const result = await fetchApiInterceptors(
        `/api/deletechef/${id}?page=${page}`,
        token,
        options
      );
      setAlert({ ...alert, show: true });
      if (result.status) {
        setError(false);
        dispatch(setChefs(result.data));
        setAlert({
          ...alert,
          show: true,
          message: "Status changed successfully to inactive!",
        });
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
      setAlert({ ...alert, message: err.message });
    } finally {
      dispatch(setLoading(false));
      setTimeout(() => {
        setAlert({ show: false, message: "" });
      }, 3000);
    }
  };

  if (!token && !user.user_id) {
    return <Navigate to="/" />;
  }
  return (
    <div className="cheflist-container">
      {alert.show && (
        <Alert
          className="alert-box"
          variant="filled"
          icon={error ? <ErrorIcon /> : <CheckIcon />}
          severity={error ? "error" : "success"}
        >
          {alert.message}
        </Alert>
      )}
      <TableContainer sx={{ maxHeight: "80vh" }} className="table-container">
        <Table
          sx={{ minWidth: 750 }}
          stickyHeader
          aria-label="sticky table"
          className="cheflist-table"
        >
          <TableHeader
            headCells={ChefListsHeader}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            // rowCount={rows.length}
          />

          <TableBody
            collapseTable={true}
            rows={visibleRows}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal for edit chef form */}
      <CommonModal 
        boxClassName="editchef_box"
        alert={alert}
        error={error}
        open={editForm}
        closeModal={closeModal}
        headerComponent={<Heading component={'h3'} content={'Edit Chef'}/>}
        // headerClassName='otp_box'
      >
        <ChefForm formClass='editchef_form' data={chefFormData} editForm setEditForm={setEditForm} setAlert={setAlert} setError={setError}/>
      </CommonModal>
    </div>
  );
}
