import React, { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import { Button, ButtonGroup, IconButton, Table } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TableHeader from "./TableHeader";
import { ChefListHeaderCollapse } from "../mocks/constants";

export default function GenericTableBody({
  rows,
  orderBy,
  order,
  handleRequestSort,
  collapseTable,
  handleDelete,
  handleEdit,
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ id: "", open: false });
  const handleClick = (e) => {};

  const handleExpand = (e, id) => {
    setOpen(!open);
    setSelected((prev) => {
      return { id, open: id === prev.id ? !prev.open : true };
    });
  };

  return (
    <TableBody className="table-body">
      {rows &&
        rows.map((row, idx) => {
          // const isItemSelected = selected.includes(row.id);
          const labelId = `enhanced-table-checkbox-${idx}`;

          return (
            <>
              <TableRow
                hover
                onClick={(event) => handleClick(event, row._id)}
                tabIndex={-1}
                key={row._id}
                // selected={isItemSelected}
                sx={{ cursor: "pointer" }}
              >
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => handleExpand(e, row._id)}
                  >
                    {selected.id === row._id && selected.open ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">
                  {`${
                    row.experience > 1
                      ? `${row.experience} yrs`
                      : `${row.experience} yr`
                  }`}
                </TableCell>
                <TableCell align="right">{row.level}</TableCell>
                <TableCell align="right">{row.cuisine}</TableCell>
                <TableCell align="right">{row.location}</TableCell>
                <TableCell align="right">{row.curSalary}</TableCell>
              </TableRow>
              {/* collapse */}
              {collapseTable && (
                <TableRow className="inner-row">
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                    className="inner-cell"
                  >
                    <Collapse
                      in={selected.id === row._id && selected.open}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Table
                          size="small"
                          aria-label="purchases"
                          stickyHeader
                          className="inner-table"
                        >
                          <TableHeader
                            inner={true}
                            headCells={ChefListHeaderCollapse}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                          />
                          <TableBody className="inner-body">
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row._id)}
                              tabIndex={-1}
                              key={row._id}
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.workLocation}
                              </TableCell>
                              <TableCell>{row.expSalary}</TableCell>
                              <TableCell>
                                {row.openToPartTime ? "Yes" : "No"}
                              </TableCell>
                              <TableCell>{row.added_by}</TableCell>
                              <TableCell>{row.status}</TableCell>
                              <TableCell colSpan={2}>
                                <ButtonGroup
                                  size="small"
                                  aria-label="action-buttons"
                                >
                                  <IconButton
                                    aria-label="edit"
                                    sx={{ color: "#F26F21" }}
                                    onClick={(e) => handleEdit(e, row._id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    sx={{ color: "#F26F21" }}
                                    onClick={(e) => handleDelete(e, row._id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  />
                </TableRow>
              )}
            </>
          );
        })}
      {/* {emptyRows > 0 && (
        <TableRow
          style={{
            height: (dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )} */}
    </TableBody>
  );
}
